import React, { useState } from "react";
import Detail from './Penjelasan/Detail'
import List from './Penjelasan/List'

import styles from '@views/LayananGratis/layanan.module.scss'
import { layanan } from "@utils/static-data";

export default function Penjelasan() {
  const [active, setActive] = useState(layanan[0]);
  return (
    <div className={styles.penjelasan}>
      <List active={active} setActive={setActive} />
      <Detail active={active} />
    </div>
  );
}
