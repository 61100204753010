import React from "react";
import styles from '@views/LayananGratis/layanan.module.scss'

export default function Titles() {
  return (
    <div className={styles.titles}>
      <h1>
        <p>6 Layanan Gratis</p>
        <p>Hanya Untuk Anda</p>
      </h1>
      <div className={styles.subTitles}>
        <p>Selalu memudahkan dan</p>
        <p>menguntungkan anda</p>
      </div>
    </div>
  );
}
