import React from "react";
import { layanan } from "@utils/static-data";
import styles from '@views/LayananGratis/layanan.module.scss'

type LayananTypes = typeof layanan[0];

type DetailProps = {
  active: LayananTypes;
  setActive: React.Dispatch<React.SetStateAction<LayananTypes>>;
};
export default function List({ active, setActive }: DetailProps) {
  return (
    <div className={styles.list}>
      <ul>
        {layanan.map((value, index) => {
          const { title } = value;
          const titleText = title.join(" ");
          return (
            <li
              key={index}
              className={active === value ? "active" : ""}
              onClick={() => setActive(value)}
            >
              <span>{index + 1}</span>
              {titleText}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
