import React from "react";
import styles from '@views/LayananGratis/layanan.module.scss'

export interface ButtonProps {
  prevSlide: React.MouseEventHandler;
}

export default function Button({ prevSlide }: ButtonProps) {
  return (
    <div className={`${styles.buttonSlides} back`} onClick={prevSlide}>
      <img src={require('@assets/images/icon/icon-arrow-up.svg')} />
      <span className={styles.back}>Kembali untuk melihat Video Penjelasan</span>
    </div>
  );
}
