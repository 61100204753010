import React from "react";
import styles from '@views/LayananGratis/layanan.module.scss'

export default function Titles() {
  return (
    <div className={styles.title} >
      <h1>
        Penjelasan <span>Layanan Gratis</span> Untuk Klien
      </h1>
    </div>
  );
}
