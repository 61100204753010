import React from "react";
import { layanan } from "@utils/static-data";
import styles from '@views/LayananGratis/layanan.module.scss'

type LayananTypes = typeof layanan[0];

type DetailProps = {
  active: LayananTypes;
};

export default function Detail({ active }: DetailProps) {
  const { title, details } = active;
  const index = layanan.indexOf(active);

  return (
    <div className={styles.detail}>
      <h5>
        <span className={styles.index}>{index + 1}.&nbsp;</span>
        {title.map((text, index) => (
          <span key={index}>{text}&nbsp;</span>
        ))}
      </h5>

      {details.map((detail, index) => (
        <p key={index}>{detail}</p>
      ))}
    </div>
  );
}
