import React from "react";
import { graphql } from "gatsby";
import { LayananGratisPage } from "src/types";
import LayananGratis from  '@views/LayananGratis'

/**
 * QUERIES FOR LAYANAN GRATIS PAGE
 *
 * Query graphql untuk get image pada folder asset
 * query ini digunakan untuk image fluid pada
 * gatsby-image
 */
export const pageQuery = graphql`
  query LayananGratis {
    # QUERY DESKTOP IMAGE
    desktopImg: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # QUERY MOBILE IMAGE
    mobileImg: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function layananGratis(props: LayananGratisPage) {
    return <LayananGratis {...props} />
}
